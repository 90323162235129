import React, {Component, useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { Container, Row, Col, Form, Table, Modal  } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import * as RiIcons from "react-icons/ri";


import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import * as IconName from "react-icons/bs";
import * as GI from "react-icons/gi";
import * as BI from "react-icons/bi";
import * as RI from "react-icons/ri";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";

import './payment-voucher.css'
import { getDateRangePickerDayUtilityClass } from '@mui/lab';
import { useParams, useHistory } from 'react-router-dom';
import { setDate } from 'date-fns';


function Paymentvoucher(props) {
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")

    const [paymentvoucherId, setPaymentvoucherid] = React.useState('');
    const [registerDate, setRegisterDate] = React.useState(new Date);
    const [refDate, setRefdate] = React.useState(new Date);
    const [refno, setRefno] = React.useState('');
    const [remarks, setRemarks] = React.useState('');
    const [billType, setBilltype] = React.useState('');
    const [invoice, setInvoice] = React.useState('');
    const [invoNum, setInvoNu] = React.useState('')
    const [paymode, setPaymode] = React.useState('');
    const [userId, setUserid] = React.useState('');
    const [modal, setModal] = React.useState(false);
    const [allmodal, setAllmodal] = React.useState(false);
    const [againstmodal, setAgainstmodal] = React.useState(false);

    const [ledgercode, setLedgercode] = React.useState("");
    const [ledgername, setLedgername] = React.useState("");
    const [ledgerid, setLedgerid] = React.useState("");

    const [paymentcode, setPaymentcode] = React.useState("");
    const [paymentname, setPaymentname] = React.useState("");
    const [paymenttype, setPaymenttype] = React.useState("");
    const [paymentamount, setPaymentamount] = React.useState("");
    const [paymentaccid, setPaymentaccid] = React.useState("");

    const [accountlist, setAccountlist] = React.useState([]);
    const [billtypelist, setBilltypelist] = React.useState([]);
    const [allaccountlist, setAllaccountlist] = React.useState([]);
    const [purchaselist, setPurchaselist] = React.useState([]);
    const {id} = useParams()
    const [paymentId, setPaymentid] = React.useState("");
    const [editmode, setEditmode] = React.useState(false);
    
    const [amount, setAmount] = React.useState("");
    const [tableRow, setTablerow] = React.useState([]);

    const [total, setTotal] = React.useState("");

    const [purchaseid, setPurchaseid] = React.useState("");
    const [billinvoice, setBillinvoice] = React.useState("");

    const [bpaymode, setBpaymode] = React.useState("");
    const [bank, setBank] = React.useState("");
    const [pdcamount, setPdcamount] = React.useState("");
    const [banklist, setBanklist] = React.useState([]);

    const [chequeno, setChequeno] = React.useState("");
    const [entrydate, setEntrydate] = React.useState(new Date);
    const [postdate, setPostdate] = React.useState(new Date);
    const [accbalance, setAccbalance] = React.useState("");
    let history = useHistory();
    const [wordEntered, setWordEntered] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [type, setType] = React.useState("");
   
    const billtypeChange = (event) => {
        setBilltype(event.target.value);
        setType(event.target.value)
        getInvoiceno()
      };

      useEffect(() => {
        getInvoiceno(billType)
        // console.log('billType', billType)
    },[billType])

      const paymodeChange = (event) => {
        setPaymode(event.target.value);
        setBpaymode("")
      };

      const bpaymodeChange = (event) => {
        setBpaymode(event.target.value);
      };

      const getBalance = (id) => {
        const data = {
            "ledgerid": id,
          }
          fetch(global.url + "accountBalanceByBank", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
            //   console.log("balance response", responseJson)
              setAccbalance(responseJson.data)
          })
          .catch((error)=>{
              console.log(error)
              
          })
      }

      const bankChange =(event) => {
        setBank(event.target.value)
        
        const data = {
            "bankmasterid": event.target.value,
          }
          fetch(global.url + "accountByBankMaster", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
            //   console.log("respone", responseJson)
              setLedgerid(responseJson.data.bankmaster_ledgername)
              setLedgercode(responseJson.data.bankmaster_bankcode)
              setLedgername(responseJson.data.bankmaster_bankcode)
              getBalance(responseJson.data.bankmaster_ledgername)
          })
          .catch((error)=>{
              console.log(error)
              
          })
      }

      const selectAccount = () => {
        setModal(true)
        getAccountlist()
      }

      const getAccountlist = () => {
        const data = {
          "payment_mode": paymode,
        }
        fetch(global.url + "viewPaymentByMode", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            setAccountlist(responseJson.data)
        })
        .catch((error)=>{
            console.log(error)
            
        })
      }
      useEffect(() => {
        getTotalammount()
       
        console.log("content123",amount )
      },[tableRow])

      useEffect(() => {
        console.log("id", id)
       
        getBilltype()
        if(id == "add") {
            // getInvoiceno()
            setEditmode(false)
        }
        else{
           getDetailsbyid(id)
           setEditmode(true)
        }
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
        getbankdata()
      }, []);


      const getbankdata =() => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
        
  
        var data = {
          "usertype": userdata.user_type,
          "companyid": company
        }
        fetch(global.url + "viewBankmaster", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              if(responseJson.status == "false") {
               
                setBanklist(responseJson.data)
                // console.log("response", responseJson.data)
              }
              else{
               
              }
          })
          .catch((error)=>{
              console.log(error)
          })
      }


      const getTotalammount = () => {
          var totalAmount = 0;
          for (var i = 0; i < tableRow.length; i++) {
            totalAmount += parseInt(tableRow[i].pay_amount)
         }
            setAmount(totalAmount)
      }

      const getInvoiceno = (id) => {
          var data = {
              "id" : id
          }
          console.log('type', data)
        fetch(global.url + "billset_invoice", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
            //   console.log('sj77',responseJson)
              
              if(responseJson.error == false){
            
                setInvoice(responseJson.data)
                setInvoNu(responseJson.data)
              }
          })
          .catch((error)=>{
              console.log(error)
          })
      }


      const getDetailsbyid = (id) => {
            const data = {
                "payment_id": id,
            }
            fetch(global.url + "viewPayment", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                // console.log("payment head", responseJson)
                if(responseJson.status == "false") {
                    setBilltype(responseJson.data.payment_bill_type)
                    setInvoice(responseJson.data.payment_invoice_no)
                    var invoiceDate=new Date(responseJson.data.payment_date).toISOString();
                    var referenedate=new Date(responseJson.data.payment_ref_date).toISOString();
                    setRegisterDate(invoiceDate)
                    setRefdate(referenedate)
                    setRefno(responseJson.data.payment_ref_no)
                    setPaymode(responseJson.data.payment_mode)
                    setLedgercode(responseJson.data.payment_account_code)
                    setLedgername(responseJson.data.payment_account_name)

                    console.log("payment table", responseJson.details)
                    setTablerow(responseJson.details)
                    setRemarks(responseJson.data.payment_remarks)
                    setPaymentid(responseJson.data.payment_id)
                    setLedgerid(responseJson.data.payment_account_id_fk)
                }
            })
            .catch((error)=>{
                console.log(error)
            })
      }

      const getBilltype = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
  
        var data = {
          "type":  userdata.user_type,
          "companyid": company,
          "id" : 1
        }
        fetch(global.url + "billsett_tranNames", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("sj response", responseJson)
              setBilltypelist(responseJson.data)
            //   setType(responseJson.data.billset_billno)
            setBilltype(responseJson.data[0].billset_id)
              
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      
      const selectedAcc = (name, code, id) => {
          console.log("click")
            setLedgercode(code)
            setLedgername(name)
            setLedgerid(id)
            setModal(false)
      }

      const addRow = () => {
          if(paymentcode == "") {
              return alert("Code required")
          }

          if(paymentamount == "") {
            return alert("Amount required")
          }

        setTablerow([...tableRow, {pay_code: paymentcode, pay_account_id: paymentaccid, pay_amount: paymentamount, pay_account_name: paymentname, pay_type: paymenttype, pay_purchase_fk: purchaseid,}])
        setPaymentcode("")
        setPaymentname("")
        setPaymenttype("")
        setPaymentaccid("")
        setPaymentamount("")
        setPurchaseid("")
        setTotal(paymentamount)
        // getPayment()
    }

    // const getPayment = () => {
    //   const totalPayment = +total + +paymentamount
    //     setTotal(totalPayment)
    // }

        
    const tableselectedAccount = (code, name, id, type) => {
        setPaymentcode(code)
        setPaymentname(name)
        setPaymenttype(type)
        setPaymentaccid(id)
        setAllmodal(false)
        setPaymentamount("")
        setPurchaseid("")
    }
    const selectAccounts = (idCode, idName, idId) => {
        setLedgercode(idCode)
        setLedgername(idName)
        setLedgerid(idId)
        setFilteredData([])
    }

    const addCode = () => {
        getAllaccountlist()
        setAllmodal(true)
    }


    const getAllaccountlist = () => {
        
        fetch(global.url + "allTransctionList", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
            //   console.log("list response", responseJson)
              setAllaccountlist(responseJson.data)
          })
          .catch((error)=>{
              console.log(error)
              
          })
    }

    const onSave = () =>{
        // console.log("data", registerDate.toISOString().split('T')[0])
        // console.log("payment_user_id", userId)
        // console.log("invoice_no", "23")
        // console.log("reference_no", refno)
        // console.log("payment_date", registerDate.toISOString().split('T')[0])
        // console.log("reference_date", refDate.toISOString().split('T')[0])
        // console.log("payment_id",paymentvoucherId)
        // console.log("bill_type", billType)
        // console.log("payment_mode", paymode)
        // console.log("payment_remarks", remarks)
        // console.log("pay_table", JSON.stringify(tableRow))
        // console.log("account_code", ledgercode)
        // console.log("account_name", ledgername)
        // console.log("payment_account_id_fk", ledgerid)
        // var userdata = JSON.parse(localStorage.getItem("user"))
        // setUserid(userdata.user_id)

        // const formData = new FormData();
        // formData.append("payment_user_id", userId)
        // formData.append("invoice_no", "23")
        // formData.append("reference_no", refno)
        // formData.append("payment_date", registerDate.toISOString().split('T')[0])
        // formData.append("reference_date", refDate.toISOString().split('T')[0])
        // formData.append("payment_id",paymentvoucherId)
        // formData.append("bill_type", billType)
        // formData.append("payment_mode", paymode)
        // formData.append("payment_remarks", remarks)
        // formData.append("pay_table", JSON.stringify(tableRow))
        // formData.append("account_code", ledgercode)
        // formData.append("account_name", ledgername)
        // formData.append("payment_account_id_fk", ledgerid)
        var paymentdate;
        var refdate;
        if(editmode == true) {
            paymentdate = registerDate.split('T')[0]
            refdate = refDate.split('T')[0]
        }
        else{
            paymentdate = registerDate.toISOString().split('T')[0]
            refdate = refDate.toISOString().split('T')[0]
        }
        // console.log("paymentId", paymentId)
        const data = {
            "payment_id": paymentId,
            "payment_user_id":userId,
            "invoice_no": invoice,
            "reference_no": refno,
            "payment_date": paymentdate,
            "reference_date": refdate,
            "bill_type": billType,
            "payment_mode": paymode,
            "payment_remarks": remarks,
            "pay_table": tableRow,
            "account_code": ledgercode,
            "account_name": ledgername,
            "payment_account_id_fk": ledgerid,
            "payment_branchid" : userbranchid,
            "payment_companyid" : usercompanyid,
            "bank_type" : bpaymode,
            "post_date": entrydate.toISOString().split('T')[0], 
            "chequeno": chequeno, 
            "cheque_date": postdate.toISOString().split('T')[0], 
            "pdc_amount": pdcamount,
            "pdc_ledgerid": tableRow.pay_account_id,
            "type": tableRow.pay_type
        }
        console.log("data payment 123", data)
        fetch(global.url + "updatePayment", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("response", responseJson)
            if(responseJson.error == true) {
                // alert(responseJson.data)
                alert(responseJson.data)
            }
            else{
                alert(responseJson.message)
                window.location.reload();
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const deleteClick = (index, id, invoice) => {
        deleteFromApi(id)
        var input = [...tableRow]
        input.splice(index, 1)
        setTablerow(input)
    }

    const deleteFromApi = (id) => {
        if(window.confirm('Are you Want to delete this ? It will be deleted from your database')){
        var userdata = JSON.parse(localStorage.getItem("user"))
        const data = {
            "pay_detail_id" : id,
            "invoice": invoice,
            "userid": userdata.user_id
        }
        console.log("data detid", data)
        fetch(global.url + "deletepaymentinEdit", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("del respo", responseJson)
            if(responseJson.error == true) {
                alert(responseJson.message)
            }
            else{
                alert(responseJson.message)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
        }else{
            window.location.reload()
        }
    }
    

    const getBill = () => {
        setAgainstmodal(true)

        const data = {
            "supplier_id": paymentaccid,
            "type": "supplier",
        }
        console.log("againstTransaction data", data)
        fetch(global.url + "againstTransaction", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("bill details", responseJson)
            if(responseJson.error == false){
                setPurchaselist(responseJson.data)
            }
            else{
                alert("No Record Found")
            }
            
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const billSelect = (total, id, bill) => {
        setPaymentamount(total)
        setPurchaseid(id)
        setBillinvoice(bill)
        setAgainstmodal(false)
    }

    const upClick = () => {
        setInvoice(preCount => preCount + 1)
    }

    const downClick = () => {
        if(invoice > 1){
            setInvoice(preCount => preCount - 1)
        }
    }
    const purchaseDetails = () => {
        getPurchaseDetails()
    }
    const getPurchaseDetails = () => {
        var data = {
            'invoice_no' : invoice
          }
          console.log("data", data)
          fetch(global.url + "PaymentdetailByInvoiceno", {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson) => {
              if(responseJson.error == false){
                setBilltype(responseJson.data.billtype)
                setRefno(responseJson.data.refno)
                setRefdate(responseJson.data.refdate)
                setRegisterDate(responseJson.data.date)
                setPaymode(responseJson.data.paymode)
                setLedgercode(responseJson.data.code)
                setLedgername(responseJson.data.account)
                if(responseJson.data.details){
                    setTablerow(responseJson.data.details)
                }
              }
          })
          .catch((error)=>{
              console.log(error)
          })
    }

    const handleSearch = (event) => {
        const searchWord = event.target.value;
        setLedgername(searchWord);
        var data = {
            "name" : ledgername,
        }
        console.log("data search", data)
        fetch(global.url + "SelectAccount",{
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'APPKEY': 'erp6129'
            },
            body: JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log('response search', responseJson)
            if(responseJson.error == false){
                setFilteredData(responseJson.data)
            } else {
                setFilteredData([])
            }
        })
        .catch((error) => {
            console.log(error)
        })
    }


    const valueEdit = (id, index) => {
        var input  = [...tableRow]
        input[index].pay_amount = id
        setTablerow(input)
    }


  return (
        <div className="main-content">
            <div style={{background: '#92c99f', width: '100%', padding: 10, display: 'flex'}}>
                <img src={require("../../../assets/images/icons/payment-voucher.svg").default} />
                <p style={{marginBottom: 'unset', color: '#fff', fontWeight: 'bold', marginLeft: 10}}>Payment Voucher</p>
            </div>
            <Row>
                <Col md={12}>
                    <Row>
                        <Col md={2} style={{marginTop: 30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                    size="small"
                                        label="Date"
                                        value={registerDate}
                                        onChange={(newValue) => {
                                        setRegisterDate(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Col>
                        <Col md={2} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                    <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Bill Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={billType}
                                        label="Age"
                                        onChange={billtypeChange}
                                        size="small"
                                        
                                    >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {billtypelist.map((row, index) => {
                                return (
                                    <MenuItem value={row.billset_id}>{row.billset_formtype}</MenuItem>
                                )})}
                                    </Select>
                            </FormControl>
                        </Col>
                        {/* <Col md={2} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <TextField label="Invoice" value={invoice} onChange={(e) => setInvoice(e.target.value)} id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col> */}
                        <Col md={2} style={{marginTop: 30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <TextField label="Ref No" value={refno}  onChange={(e) => setRefno(e.target.value)} id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col md={2} style={{marginTop: 30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                        size="small"
                                            label="Ref Date"
                                            value={refDate}
                                            onChange={(newValue) => {
                                            setRefdate(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                </LocalizationProvider>
                            </FormControl>
                        </Col>
                        <Col md={2} style={{marginTop: 30}}>
                            <FormControl sx={{  width: '100%' }}>
                                    <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Pay Mode</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={paymode}
                                        label="Age"
                                        onChange={paymodeChange}
                                        size="small"  
                                    >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                        <MenuItem value={"Cash"}>Cash Account</MenuItem>
                                        <MenuItem value={"Bank"}>Bank Account</MenuItem>
                                    </Select>
                            </FormControl>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* <Col sm={6} style={{marginTop:20}}>
                            <Button onClick={selectAccount} variant="outlined">SELECT ACCOUNT</Button>
                        </Col> */}
            {paymode == "Bank" ? 
                    <Row style={{marginTop: 20}}>
                        <Col md={3} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                    <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Bank Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={bpaymode}
                                        label="Age"
                                        onChange={bpaymodeChange}
                                        size="small"  
                                    >
                                   
                           
                               
                                        <MenuItem value={"transfer"}>Transfer</MenuItem>
                                        {/* <MenuItem value={"pdc"}>PDC</MenuItem> */}
                                    </Select>
                                    {/* <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={bpaymode}
                                        label="Age"
                                        onChange={bpaymodeChange}
                                        size="small"  
                                    >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                        <MenuItem value={"transfer"}>Transfer</MenuItem>
                                        <MenuItem value={"pdc"}>PDC</MenuItem>
                                    </Select> */}
                            </FormControl>
                        </Col>

                        <Col md={3} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                    <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Bank Account</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={bank}
                                        label="Age"
                                        onChange={bankChange}
                                        size="small"  
                                    >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {banklist.map((row, index) => {
                                        return (
                                                <MenuItem value={row.bankmaster_id}>{row.bankmaster_bankcode}</MenuItem>
                                        )})
                                    }
                                    </Select>
                            </FormControl>
                            <p style={{color: 'red'}}>Balance: {accbalance}</p>
                        </Col>
                        <Col md={3} style={{marginTop:30}}>
                            <FormControl component="fieldset">
                                <TextField autoComplete='off' type="number"  onChange={(e) => setPdcamount(e.target.value)} value={pdcamount} id="outlined-basic" label="Amount" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        {
                            bpaymode == "pdc" ?
                            <Row style={{marginTop: 20}}>
                                
                                <Col md={3} style={{marginTop: 30}}>
                                    <FormControl sx={{  width: '100%' }}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                size="small"
                                                    label="Entrydate"
                                                    value={entrydate}
                                                    onChange={(newValue) => {
                                                    setEntrydate(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Col>
                                <Col md={3} style={{marginTop: 30}}>
                                    <FormControl sx={{  width: '100%' }}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                size="small"
                                                    label="Postdate"
                                                    value={postdate}
                                                    onChange={(newValue) => {
                                                    setPostdate(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Col>
                                <Col md={3} style={{marginTop:30}}>
                                    <FormControl component="fieldset">
                                        <TextField autoComplete='off'  onChange={(e) => setChequeno(e.target.value)} value={chequeno} id="outlined-basic" label="Chequeno" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                                
                            </Row> : 
                            null
                        }
                    </Row>: 
                    <Row>
                        <Col md={3} style={{marginTop: 30}}>
                            <FormControl component="fieldset">
                                <TextField  value={ledgercode} id="outlined-basic" disabled label="Code" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col md={3} style={{marginTop: 30}}>
                            <div style={{position: 'relative'}}>
                                <FormControl style={{width: '100%'}} component="fieldset">
                                    <TextField autoComplete='off' value={ledgername} onChange={handleSearch} id="outlined-basic" label="Account" variant="outlined"  size="small"/>
                                </FormControl>
                                {filteredData.length == 0 ? null:
                                    <div className="dataResult">
                                        {filteredData.map((value, key) => {
                                            return (
                                            <a onClick={() => selectAccounts(value.ledger_code, value.ledger_name, value.ledger_id)} className="searchItem" target="_blank">
                                                <div style={{marginTop:10, display: 'flex'}}> 
                                                    <p style={{fontSize: 12, marginLeft: 10}}>{value.ledger_name}</p>
                                                </div>
                                                <div style={{width: '100%', height: 1, backgroundColor: '#d3d3d3'}}></div>
                                            </a>
                                            );
                                        })}
                                    </div>
                                }
                            </div>
                        </Col>
                        <Col sm={6} style={{marginTop:20}}>
                            <Button onClick={selectAccount} variant="outlined">SELECT ACCOUNT</Button>
                        </Col>
                    </Row>
                }
            {/* <Row style={{marginTop: 30}}>
                {purchaseid != "" ? 
                    <p style={{fontSize: 10, color: 'green'}}>Against bill - {billinvoice}</p> : null
                    }
            </Row> */}
            <Row >
                <Col md={2} style={{marginTop:30}}>
                    <FormControl component="fieldset">
                        <TextField onClick={addCode} value={paymentcode}  id="outlined-basic" label="Code" variant="outlined"  size="small"/>
                    </FormControl>
                </Col>
                <Col md={3} style={{marginTop:30}}>
                    <FormControl component="fieldset" style={{width: '100%'}}>
                        <TextField id="outlined-basic" disabled  value={paymentname} label="Account" variant="outlined"  size="small"/>
                    </FormControl>
                </Col>
                <Col md={2} style={{marginTop:30}}>  
                    <FormControl component="fieldset">
                        <TextField id="outlined-basic" type="number" value={paymentamount} onChange={(e) => setPaymentamount(e.target.value)} label="Amount" variant="outlined"  size="small"/>
                    </FormControl>
                </Col>
                {/* <Col sm={2} style={{marginTop:20}}>
                    <Button onClick={getBill} variant="outlined">Against Bill</Button>
                </Col> */}
                {bpaymode == "pdc" && tableRow.length >= 1 ?
                    null : 

                    <Col sm={2} style={{marginTop:30}}>
                        <Button onClick={addRow} variant="outlined">ADD ACCOUNT</Button>
                    </Col>
                }
                
            </Row>
            <Row style={{marginTop: 20}}>
                <Col xs={12}>
                    <Table bordered>
                        <thead>
                            <tr>
                            <th>SlNo</th>
                            <th style={{width: '20%'}}>Account Code</th>
                            <th style={{width: '50%'}}>Account</th>
                            <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableRow.map((row, index) => {
                                        return (
                                            <tr>
                                                <td>{index+1}</td>
                                                <td>{row.pay_code}</td>
                                                <td>{row.pay_account_name}</td>
                                                <td><TextField id="outlined-basic" type="number" value={row.pay_amount} onChange={(e) => valueEdit(e.target.value, index)} variant="outlined"  size="small"/></td>
                                                <td >
                                                    <a href="javascript:void(0)" onClick={() => deleteClick(index, row.pay_detail_id, row.payment_invoice_no)}>
                                                        <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                                    </a>
                                                </td>
                                            </tr>
                                        )}
                                    )}
                                    <tr>
                                        <td colSpan="3" style={{textAlign: 'right', fontWeight: 'bold'}}>Total</td>
                                        <td>{amount}</td>
                                    </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row style={{marginTop: 20}}>
                <Col xs={12}>
                    <TextareaAutosize 
                    value={remarks}  onChange={(e) => setRemarks(e.target.value)}
                        minRows={3}
                        placeholder="Remarks"
                        style={{background: 'transparent', width: '100%'}}
                    />
                </Col>
            </Row>
            
            <Row style={{marginTop: 20}}>
                <Col xs={12} >
                    <ul className="list-inline">
                        <li><Button onClick={onSave} style={{backgroundColor: '#92c99f', border: '#92c99f'}} variant="outlined"><IconName.BsDownload style={{marginRight: 10}}/>Save</Button></li>
                        <li><Button onClick={purchaseDetails} style={{backgroundColor: '#92c99f', border: '#92c99f'}} variant="outlined"><GI.GiBinoculars style={{marginRight: 10}}/>Find</Button></li>
                        {/* <li><Button variant="outlined"><BI.BiPrinter style={{marginRight: 10}}/>Print</Button></li>
                        <li><Button variant="outlined"><RI.RiDeleteBin6Line style={{marginRight: 10}}/>Delete</Button></li>
                        <li><Button variant="outlined"><RI.RiCloseFill style={{marginRight: 10}}/>Close</Button></li> */}
                    </ul>
                </Col>
            </Row>

            <Modal
                size="lg"
                show={modal}
                onHide={() => setModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                     Select Account
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            <ul>
                            {accountlist.map((row, index) => {
                                return (
                                    <a style={{textDecoration: 'none', listStyle: 'none'}} href="javascript:void(0)" onClick={() =>selectedAcc(row.ledger_name, row.ledger_code, row.ledger_id)}>
                                        <li className="pay-lists" style={{color: '#000',  marginTop: 5, fontSize: 15, cursor: 'pointer'}}>{row.ledger_name}</li>
                                    </a>
                                )}
                                )
                                }
                            </ul>
                        </Col>
                        
                    </Row>
                </Modal.Body>
            </Modal>


            <Modal
                size="lg"
                show={allmodal}
                onHide={() => setAllmodal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                     Select Account
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            <ul>
                            {allaccountlist.map((row, index) => {
                                return (
                                    <a style={{textDecoration: 'none', listStyle: 'none'}} href="javascript:void(0)" onClick={() =>tableselectedAccount(row.common_code, row.common_name, row.common_id, row.type)}>
                                        <li className="pay-lists" style={{color: '#000', marginTop: 5, fontSize: 15, cursor: 'pointer'}}>{row.common_name} - {row.type}</li>
                                    </a>
                                )})
                                }
                            </ul>
                        </Col>
                        
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal
                size="lg"
                show={againstmodal}
                onHide={() => setAgainstmodal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                     Select Bill
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            <ul>
                            {purchaselist.map((row, index) => {
                                return (
                                    <a style={{textDecoration: 'none', listStyle: 'none'}} href="javascript:void(0)" onClick={() => billSelect(row.purchase_total, row.purchase_id, row.purchase_invoice)}>
                                        <li className="pay-lists" style={{color: '#000',  marginTop: 5, fontSize: 15, cursor: 'pointer'}}>{row.purchase_invoice} - {row.purchase_date} - {row.purchase_total} - {row.status}</li>
                                    </a>
                                )}
                                )
                                }
                            </ul>
                        </Col>
                        
                    </Row>
                </Modal.Body>
            </Modal>
        </div>
  );
}

export default Paymentvoucher;
