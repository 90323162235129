import './App.css';
import React, {useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import AppNavigator from './router';
import './fonts/BAHNSCHRIFT 10.TTF';

function App() {

  const [userType, setUsertype] = React.useState('')
  const [branchId, setBranchid] = React.useState('')
  const [comapanyId, setCompanyId] = React.useState('')
  const [userId, setUserid] = React.useState('')

  // useEffect(() => {
  //   var userdata = JSON.parse(localStorage.getItem("user"))
  //   console.log("userdata", userdata)
  //   if(userdata != null){
  //     setBranchid(userdata.user_branch_id)
  //     setCompanyId(userdata.user_company_id)
  //     setUserid(userdata.user_id)
  //     if(userdata.user_type == '0'){
  //       setUsertype(userdata.user_type)
  //     }
  //     else if(userdata.user_type == '1'){
  //       setUsertype(userdata.user_type)
  //     }
  //     else{
  //       setUsertype("superadmin")
  //     }
  //     console.log("userType", userType)
  //   }
   
  // },[userType])
  
  // global.url = "https://posb.galaxyhomescentre.com/public/api/";
  // global.images = "https://posb.galaxyhomescentre.com/public/";
  // global.excell = "https://posb.galaxyhomescentre.com/public/storage/app/";
  // global.txt = "https://posb.galaxyhomescentre.com/public/storage/app/txtfiles/";

  global.url = "https://gulfshore.nasscript.com/core/public/api/";
  global.images = "https://gulfshore.nasscript.com/core/public/";
  global.excell = "https://gulfshore.nasscript.com/core/public/storage/app/";
  global.txt = "https://gulfshore.nasscript.com/core/public/storage/app/txtfiles/";
  

  // global.url = "https://hotncool.nasiyb.com/core/public/api/";
  // global.images = "https://hotncool.nasiyb.com/core/public/";
  // global.excell = "https://hotncool.nasiyb.com/core/public/storage/app/";
  // global.txt = "https://hotncool.nasiyb.com/core/public/storage/app/txtfiles/";
  
  // global.url = "https://baqala.nasiyb.com/core/public/api/";
  // global.images = "https://baqala.nasiyb.com/core/public/";
  // global.excell = "https://baqala.nasiyb.com/core/public/storage/app/";
  // global.txt = "https://baqala.nasiyb.com/core/public/storage/app/txtfiles/";

  // global.url = "https://demobaqala.nasiyb.com/core/public/api/";
  // global.images = "https://demobaqala.nasiyb.com/core/public/";
  // global.excell = "https://demobaqala.nasiyb.com/core/public/storage/app/";
  // global.txt = "https://demobaqala.nasiyb.com/core/public/storage/app/txtfiles/";
  
  // global.url = "http://192.168.1.46:8080/api/";
  // global.images = "http://192.168.1.46:8080/";
  // global.excell = "http://192.168.1.46:8080/storage/app/";
  // global.txt = "http://192.168.1.46:8080/storage/app/txtfiles/";



  global.proname = "Premier"
  global.website = "pos.galaxyhomescentre.com"
  // global.proname = "Nasshopy"
  // global.website = "www.nasshopy.com"
  

  return (
      <AppNavigator />

     
  );
}

export default App;
