import '../Tax-Settings/TaxSettings.css';
import React, {Component, useEffect, useState, useRef} from 'react';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col, Table } from "react-bootstrap";
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { BiRefresh } from "react-icons/bi";
import { GoSettings } from "react-icons/go";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { HiSave } from "react-icons/hi";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiFillPrinter } from "react-icons/ai";
import { MdClearAll, MdSettingsEthernet } from "react-icons/md";
import { GiCash } from "react-icons/gi";
import { AiOutlineClose, AiFillMinusCircle } from "react-icons/ai";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useHistory, useParams } from "react-router-dom";



const columns = [
    { id: '1', label: 'SI No.', minWidth: 50,span: false },
    { id: '2', label: 'Tax Name', minWidth: 50,span: false },
    { id: '3', label: 'Tax %', minWidth: 50,span: false },
    {
      id: 'Action',
      label: 'Action',
      minWidth: 100,
      span: true
    },
  ];
    
  const rows = [
    {
      company: 'Lorem Ipsum',
    },
    {
      company: 'Lorem Ipsum',
    },
    {
      company: 'Lorem Ipsum',
    },
  ];

function Quatation(props) {
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")
    const [clientname, setClientname] = React.useState('');
    const [projectname, setProjectname] = React.useState('');
    const [date, setDate] = React.useState(new Date);
    const [validdate, setValiddate] = React.useState(new Date);
    const [bankaccount, setBankaccount] = React.useState('');
    const [currancy, setCurrancy] = React.useState('');

    const [designation, setDesignation] = React.useState('');
    const [name, setName] = React.useState('');
    const [terms, setTerms] = React.useState('');
    const [discount, setDiscount] = React.useState(0);
    const [grandtotal, setGrandtotal] = React.useState(0);
    const [tableRow, setTableRow] = React.useState([{key: "", qd_details: "",qd_price: 0, qd_discount: 0, qd_total: 0, qd_status: 1, qd_id: ""}]);
    const [gst, setGst] = React.useState(false);
    const [subtotal, setSubtotal] = React.useState(0);

    const [quotationid, setQuotationid] = React.useState("");

    


    const [gstamt, setGstamt] = React.useState(0);
    const {id} = useParams()


    const addRow = () => {
        setTableRow([...tableRow,{key: "", qd_details: "", qd_price: 0, qd_discount: 0, qd_total: 0, qd_status: 1, qd_id: ""}])
    }

    const deleteRow = (index, qdid) => {
        if(qdid == ""){
            console.log("index", index)
            var input = [...tableRow]
            input.splice(index, 1)
            console.log("input", input)
            setTableRow(input)
        }
        else{
            var data = {
                "qd_id": qdid,
            }
            fetch(global.url + "QuotationDetailsDelete", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
            console.log("QuotationDetailsDelete response12", responseJson)
                alert(responseJson.message)
               window.location.reload()
            })
            .catch((error)=>{
                console.log(error)
            })
        }
       
    }

    useEffect(() => {
        console.log("useEffect quotation_id", id)
        if(id == "add") {
            setQuotationid("")
        }
        else{
            setQuotationid(id)
            getDetailsbyid(id)
        }
      }, []); 

      const getDetailsbyid = (id) => {
          console.log("quotation_id", id)
        var data = {
            "quotation_id" : id,
        }
        fetch(global.url + "QuotationDetails", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("QuotationDetails response", responseJson)
            setTableRow(responseJson.data.details)
            setClientname(responseJson.data.quotation_client_name)
            setProjectname(responseJson.data.quotation_project_name)
            setBankaccount(responseJson.data.quotation_bankaccount)
            setDesignation(responseJson.data.quotation_designation)
            setName(responseJson.data.quotation_name)
            setDiscount(responseJson.data.quotation_discount)
            setGstamt(responseJson.data.quotation_gst)
            setCurrancy(responseJson.data.quotation_currency)
            setGrandtotal(responseJson.data.quotation_grandtotal)
        
        })
        .catch((error)=>{
            console.log(error)
        })
      }

    useEffect(() => {
        getTotalamount()
      }, [tableRow]); 

      useEffect(() => {
        var input =  parseInt(subtotal) - parseInt(discount)  + parseInt(gstamt)
        setGrandtotal(input)
      }, [gstamt, discount]); 

      useEffect(() => {
        if(gst == false){
            setGstamt(0)
        }
      }, [gst]); 


      const getTotalamount = () => {
        var totalAmount = 0;
        for (var i = 0; i < tableRow.length; i++) {
            if(tableRow[i].qd_status == 1) {
                totalAmount += parseInt(tableRow[i].qd_total) 
            }
        }
        setSubtotal(totalAmount)
        setGrandtotal(parseInt(totalAmount) - parseInt(discount)  + parseInt(gstamt))
    }

    const nameChange = (event, index) => {
        var input = [...tableRow]
        input[index].qd_details = event.target.value
        setTableRow(input)
    }

    const priceChange = (event, index) => {
    
        var input = [...tableRow]
        input[index].qd_price = event.target.value
        input[index].qd_total = event.target.value - input[index].qd_discount
        setTableRow(input)
    }

    const discountChange = (event, index) => {
        var input = [...tableRow]
        input[index].qd_discount = event.target.value
        input[index].qd_total = input[index].qd_price - event.target.value
        setTableRow(input)
    }

    const currancyChange = (event) => {
        setCurrancy(event.target.value)
    }

    const blockChange = (e, index) => {
        let isChecked = e.target.checked;
        console.log(isChecked)
        if(isChecked == true) {
            var input = [...tableRow]
            input[index].qd_status = 1
            setTableRow(input)
        }
        else{
            var input = [...tableRow]
            input[index].qd_status = 0
            setTableRow(input)
        }
    }

    const blockChange1 = (e) => {
        let isChecked = e.target.checked;
        console.log(isChecked)
        if(isChecked == true) {
            setGst(true)
        }
        else{
            setGst(false)
        }
    }

    const onSave = () => {
        var data = {
            "quotation_id" : quotationid,
            "quotation_number" : "",
            "quotation_client_name" : clientname,
            "quotation_project_name" : projectname,
            "quotation_subtotal" : subtotal,
            "quotation_gst" : gstamt,
            "quotation_discount" : discount,
            "quotation_grandtotal" : grandtotal,
            "quotation_bankaccount" : bankaccount,
            "quotation_designation" : designation,
            "quotation_name" : name,
            "quotation_date" : date,
            "quotation_validupto" : validdate,
            "quotation_currency" : currancy,
            "details": tableRow
        }
        console.log("data", data)
        fetch(global.url + "QuotationAdd", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            // console.log("response management", responseJson)
            if(responseJson.error == false){
                alert(responseJson.message)
                window.location.reload()
            }
            else{
                alert(responseJson.message)
            }
        })
        .catch((error)=>{
            
            alert("Something went wrong")
            console.log(error)
        })
        
    }

 
    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20}}>
                    <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
                        <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Service Quotation</p>
                       
                    </div>
               <div>    
                    <Row>
                        <Col xs={12} md={12}>
                            <Row>
                                <Col xs={12} md={2} style={{marginTop: 20}}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                        label="Quotation Date"
                                            value={date}
                                            onChange={(newValue) => {
                                            setDate(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Col>
                                <Col xs={12} md={2} style={{marginTop: 20}}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Valid upto :"
                                            value={validdate}
                                            onChange={(newValue) => {
                                            setValiddate(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Col>
                                <Col xs={12} md={2} style={{marginTop: 20}}>
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField label="Client Name" value={clientname} onChange={(e) => setClientname(e.target.value)} id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={2} style={{marginTop: 20}}>
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField label="Project Name" autoComplete='off' onChange={(e) => setProjectname(e.target.value)} value={projectname} id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row style={{marginTop: 20}}>
                        <Col xs={12}>
                            <Table bordered responsive>
                                <thead>
                                    <tr>
                                        <th>Project Name</th>
                                        <th>Price</th>
                                        <th>Discount</th>
                                        <th>Total</th>
                                        <th>Enable</th>
                                        <th>Remove</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableRow.map((item, index) => {
                                        return (
                                            <tr >
                                                <td>
                                                    {
                                                        item.qd_id == "" ? 
                                                        <input style={{width: 200, border: 0, outline: 0}} autocomplete="off" type="text" name="query" value={item.qd_details} onChange={(event) => nameChange(event, index)} />:
                                                        <input style={{width: 200, border: 0, outline: 0}} autocomplete="off" disabled type="text" name="query" value={item.qd_details} onChange={(event) => nameChange(event, index)} />
                                                    }
                                                    
                                                </td>
                                                <td>
                                                    {
                                                        item.qd_id == "" ? 
                                                        <input style={{width: 100, border: 0, outline: 0}} autocomplete="off"  type="number" name="query" value={item.qd_price} onChange={(event) => priceChange(event, index)} />:
                                                        <input style={{width: 100, border: 0, outline: 0}} autocomplete="off" disabled type="text" name="query" value={item.qd_price} onChange={(event) => priceChange(event, index)} />
                                                    }
                                                </td>
                                                <td>
                                                {
                                                    item.qd_id == "" ? 
                                                    <input style={{width: 100, border: 0, outline: 0}} autocomplete="off"  type="number" name="query" value={item.qd_discount} onChange={(event) => discountChange(event, index)} />:
                                                    <input style={{width: 100, border: 0, outline: 0}} autocomplete="off" disabled  type="number" name="query" value={item.qd_discount} onChange={(event) => discountChange(event, index)} />
                                                }
                                                </td>
                                                <td>
                                                    {item.qd_total}
                                                </td>
                                                <td>
                                                    {
                                                        item.qd_id == "" ? 
                                                        <FormControl sx={{  width: '100%' }}>
                                                            <FormControlLabel control={
                                                            <Checkbox  checked = {item.qd_status == 1 ? true: false} onChange={e => blockChange(e, index)} />
                                                            } label="" />
                                                        </FormControl> :
                                                        <FormControl sx={{  width: '100%' }}>
                                                            <FormControlLabel control={
                                                            <Checkbox disabled checked = {item.qd_status == 1 ? true: false} onChange={e => blockChange(e, index)} />
                                                            } label="" />
                                                        </FormControl>
                                                    }

                                                </td>
                                                <td>
                                                    <Button onClick={() => deleteRow(index, item.qd_id)} style={{backgroundColor: '#fff', border: 'none', boxShadow: 'none'}} variant="contained">< AiFillMinusCircle color="#fc0303" fontSize={30} style={{marginRight: 10}} /></Button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    
                                </tbody>
                            </Table>
                           
                        </Col>
                    </Row> 
                    <Row>
                        <Col xs ={12} md={6}>
                            <Button onClick={onSave} style={{backgroundColor: '#92c99f', borderRadius: 20, marginTop:20, marginBottom: 20}} variant="contained"><HiSave  color="White" fontSize={20} style={{marginRight: 10}} />Save</Button>
                            {/* <Button style={{backgroundColor: '#6d37b9', marginLeft: 20, borderRadius: 20, marginTop:30, marginBottom: 20}} variant="contained"><RiDeleteBinLine  color="White" fontSize={20} style={{marginRight: 10}} />Delete</Button>
                            <Button style={{backgroundColor: '#6d37b9', marginLeft: 20, borderRadius: 20, marginTop:30, marginBottom: 20}} variant="contained"><AiFillPrinter  color="White" fontSize={20} style={{marginRight: 10}} />Print</Button>
                            <Button style={{backgroundColor: '#6d37b9', marginLeft: 20, borderRadius: 20, marginTop:30, marginBottom: 20}} variant="contained"><MdClearAll  color="White" fontSize={20} style={{marginRight: 10}} />Clear</Button>
                            <Button style={{backgroundColor: '#6d37b9', marginLeft: 20, borderRadius: 20, marginTop:30, marginBottom: 20}} variant="contained"><GiCash  color="White" fontSize={20} style={{marginRight: 10}} />GST</Button>
                            <Button style={{backgroundColor: '#6d37b9', marginLeft: 20, borderRadius: 20, marginTop:30, marginBottom: 20}} variant="contained"><AiOutlineClose color="White" fontSize={20} style={{marginRight: 10}} />Close</Button> */}
                        </Col>
                        <Col xs ={12} md={6}>
                            <Button onClick={() => addRow()} style={{float: 'right', marginRight: 20, backgroundColor: '#fff', color: '#000', borderRadius: 20, marginTop: 20}} variant="contained">Add New</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col  xs={12}>
                            <Row>
                                <Col xs={12} md={2} style={{marginTop: 20}}>
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField label="Sub Total" disabled autoComplete="off" type="number" onChange={(e) => setSubtotal(e.target.value)} value={subtotal} id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={2} style={{marginTop: 20}}>
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField label="Discount" value={discount} onChange={(e) => setDiscount(e.target.value)} id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>  
                                
                                {/* <Col xs={12} md={2} style={{marginTop: 20}}>
                                    <div style={{display: 'flex'}}>
                                        <FormControl sx={{  width: '100%' }}>
                                            <FormControlLabel control={
                                            <Checkbox  onChange={e => blockChange1(e)} />
                                            } label="Add GST" />
                                        </FormControl>
                                        {
                                            gst ? 
                                            <FormControl sx={{width: '100%' }}>
                                                <TextField InputProps={{ inputProps: { min: 0, max: 10 } }} type="number" value={gstamt} onChange={(e) => setGstamt(e.target.value)} id="outlined-basic" variant="outlined"  size="small"/>
                                            </FormControl> : 
                                            null
                                        }
                                       
                                    </div>
                                </Col> */}
                                <Col xs={12} md={2} style={{marginTop: 20}}>
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField label="Grand Total" disabled autoComplete="off" type="number" onChange={(e) => setGrandtotal(e.target.value)} value={grandtotal} id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={2} style={{marginTop: 20}}>
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField label="Bank Account" value={bankaccount} onChange={(e) => setBankaccount(e.target.value)} id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={2} style={{marginTop: 20}}>
                                    <FormControl sx={{width: '100%' }}>
                                            <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Currency</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={currancy}
                                                label="Age"
                                                onChange={currancyChange}
                                                size="small"
                                            >
                                                <MenuItem value="INR">INR - Indian Rupee</MenuItem>
                                                <MenuItem value="AED">AED - UAE Dirhams</MenuItem>
                                                <MenuItem value="KD">KD - Kuwaiti Dinar</MenuItem>
                                                <MenuItem value="QAR">QAR - Qatar Rial</MenuItem>
                                                <MenuItem value="USD">USD - American Dollar</MenuItem>
                                            </Select>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={2} style={{marginTop: 20}}>
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField label="Name" value={name} onChange={(e) => setName(e.target.value)} id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={2} style={{marginTop: 20}}>
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField label="Designation" value={designation} onChange={(e) => setDesignation(e.target.value)} id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={3} style={{marginTop: 20}}>
                                    <p>Terms and Conditions :</p>
                                </Col>
                                <Col xs={12} md={5} style={{marginTop: 20}}>
                                    {/* <FormControl sx={{width: '100%' }}>
                                        <TextField value={terms} onChange={(e) => setTerms(e.target.value)} id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl> */}
                                    <textarea value={terms} onChange={(e) => setTerms(e.target.value)} rows="2" class="form-control" name="terms_conditions" id="terms_conditions" style={{textTransform: 'capitalize'}} required="" autocomplete="off"> 
                                    </textarea>
                                </Col>
                            </Row>
                        </Col>
                    </Row> 
                </div>
            </div>
          </Paper> 
      </div>
    )
}
export default Quatation;