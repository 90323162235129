import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Modal } from "react-bootstrap";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
// import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import ReactToPrint from 'react-to-print';
import FormControl from '@mui/material/FormControl';
import { useReactToPrint } from 'react-to-print';
import Barcode from 'react-barcode';

function PosSummery() {
    const componentRef = useRef();

    const [dateFrom, setDatefrom] = React.useState(new Date)
    const [dateTo, setDateTo] = React.useState(new Date)
    const [source, setSource] = React.useState([]);
    const [modal3, setModal3] = useState(false)
    const [payable , setpayable] = React.useState("")

    // print
    const [datep, setDatep] = React.useState("")
    const [vouchernop, setVouchernop] = React.useState("")
    const [detailsp, setDetailsp] = React.useState([])
    const [salestotalp, setTotalp] = React.useState(0)
    const [discountp, setDiscountp] = React.useState(0)
    const [grandtotalp, setGrandtotalp] = React.useState(0)
    const [cashp, setCashp] = React.useState(0)
    const [cashpb, setCashpb] = React.useState(0)
    const [cardp, setCardp] = React.useState(0)
    const [cashbillp, setCashbillp] = React.useState(0)
    const [paidp, setPaidp] = React.useState(0)
    const [balancep, setBalancep] = React.useState(0)
    const [mobilep, setMobilep] = React.useState("");
    const [billno, setBillno] = React.useState("");

    const [netTot, setNettot] = React.useState("");
    const [discTot, setDisctot] = React.useState("");
    const [cashTot, setCashtot] = React.useState("");
    const [cardTot, setCardtot] = React.useState("");
    const [totTot, setTottot] = React.useState("");

    const [username, setUsername] = useState("")
    const [branchName, setBranchName] = React.useState("");
    const [userbranchid, setUserbranchid] = useState("")

    useEffect(() => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        var username = JSON.parse(localStorage.getItem("user_name"))
        console.log("userdata", userdata)
        setUsername(userdata.user_name)
        setUserbranchid(branch)
        setBranchName(userdata.branch_name)
    }, []);

    useEffect(() => {
        getdata()
    }, [])

    const getdata = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        var data = {
            "userid": userdata.user_id,
            "type": userdata.user_type,
            "companyid": company,
            "fromdate": dateFrom.toISOString().split('T')[0],
            "todate": dateTo.toISOString().split('T')[0],
            "billno": billno
        }
        console.log("possummary", data)
        fetch(global.url + "posSalesReport", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error == false) {
                    setSource(responseJson.data)
                    setNettot(responseJson.total.nettotal)
                    setDisctot(responseJson.total.discounttotal)
                    setCashtot(responseJson.total.cashpaid)
                    setCardtot(responseJson.total.bankpaid)
                    setTottot(responseJson.total.totalpaid)
                }
                else {
                    setSource([])
                }
                console.log("response possummary", responseJson)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getSalesbyid = (id) => {
        var data = {
            "sales_id": id,
        }
        fetch(global.url + "salesPrint", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("print details", responseJson)
                setDetailsp(responseJson.data.details)
                setTotalp(responseJson.data.sales_total)
                setVouchernop(responseJson.data.sales_invoice)
                setDatep(responseJson.data.sales_date)
                setDiscountp(responseJson.data.sales_discount)
                setCashp(responseJson.data.sales_cashreceived)
                setCashpb(responseJson.data.sales_cash)
                setCardp(responseJson.data.sales_card)
                setMobilep(responseJson.data.customer_mobile)
                setCashbillp(responseJson.data.sales_cash)
                setPaidp(parseFloat(responseJson.data.sales_total))
                setpayable(responseJson.data.payable)
                setModal3(true)
                handlePrint()
                //   window.print()
                //   window.location.reload()
                setTimeout(function () {
                    window.location.reload()
                }, 1000);
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const formatDate = (datestring) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
        }

        var date = new Date(datestring)
        var month = pad2(date.getMonth() + 1);//months (0-11)
        var day = pad2(date.getDate());//day (1-31)
        var year = date.getFullYear();

        const timeString12hr = new Date(datestring)
            .toLocaleTimeString('en-US',
                { timeZone: 'Asia/Kuwait', hour12: true, hour: 'numeric', minute: 'numeric', second: 'numeric' }
            );
        //   console.log("timeString12hr", timeString12hr)

        var formattedDate = day + "/" + month + "/" + year + " " + timeString12hr;
        return formattedDate; //03/02/2021
    }

    return (
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2 }}>
                <div style={{ margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                    <p style={{ fontSize: 25, marginBottom: 0, fontWeight: 'bold' }}>Pos Summary</p>
                </div>
                <div style={{ margin: 20, marginTop: 10 }}>
                    <Row>
                        <Col xs={12} md={2} style={{ marginTop: 25 }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="From"
                                    value={dateFrom}
                                    onChange={(newValue) => {
                                        setDatefrom(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Col>
                        <Col xs={12} md={2} style={{ marginTop: 25 }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="To"
                                    value={dateTo}
                                    onChange={(newValue) => {
                                        setDateTo(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Col>
                        <Col md={3}>
                            <FormControl style={{ marginTop: 25 }} sx={{ width: '100%' }}>
                                <TextField value={billno} onChange={(e) => setBillno(e.target.value)} id="outlined-basic" label="Bill No." variant="outlined" size="small" />
                            </FormControl>
                        </Col>
                        <Col xs={12} md={3} lg={2} style={{ marginTop: 20 }}>
                            <Button variant="primary" onClick={() => getdata()}>Filter</Button>
                        </Col>
                        {/* <Col xs={12} md={2} >
                            <ReactToPrint
                                trigger={() => <Button style={{backgroundColor: 'blue', color: '#fff', marginTop: 20, width: '50%'}} variant="contained">Print</Button>}
                                content={() => componentRef.current}
                            />
                        </Col> */}
                    </Row>
                </div>
                <TableContainer sx={{ maxHeight: 440, mt: 2 }}>
                    <div style={{ width: '100%' }}>
                        <Table id="table-to-xls" stickyHeader aria-label="sticky table">
                            <TableHead className="tableheaderexe">
                                <TableRow>

                                    <TableCell colSpan={12} style={{ padding: 0 }}>
                                        <div className='captiontable'>
                                            <p style={{ display: 'flex', justifyContent: 'center', fontSize: 16 }} >Pos Summary Report</p>
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow >
                                    <TableCell className='tablecellPrint' style={{ fontWeight: 'bold', width: '5%' }}>
                                        SL No
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{ fontWeight: 'bold', width: '10%' }}>
                                        Date
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{ fontWeight: 'bold', width: '5%' }}>
                                        Time
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{ fontWeight: 'bold', width: '10%' }}>
                                        Bill No.
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{ fontWeight: 'bold', width: '5%' }}>
                                        Counter
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{ fontWeight: 'bold', width: '5%' }}>
                                        User
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{ fontWeight: 'bold', width: '8%' }}>
                                        Net Total
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{ fontWeight: 'bold', width: '8%' }}>
                                        Discount
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{ fontWeight: 'bold', width: '8%' }}>
                                        Cash Paid
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{ fontWeight: 'bold', width: '8%' }}>
                                        Bank Paid
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{ fontWeight: 'bold', width: '8%' }}>
                                        Total paid
                                    </TableCell>

                                    <TableCell className='tablecellPrint noprint' style={{ fontWeight: 'bold', width: '15%' }}>
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {source
                                    .map((row, index) => {
                                        return (
                                            <TableRow tabIndex={-1} key={row.code}>
                                                <TableCell className='tablecellPrint'>
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell className='tablecellPrint'>
                                                    {row.date}
                                                </TableCell>
                                                <TableCell className='tablecellPrint'>
                                                    {row.time}
                                                </TableCell>
                                                <TableCell className='tablecellPrint'>
                                                    {row.billno}
                                                </TableCell>
                                                <TableCell className='tablecellPrint'>
                                                    {row.counter}
                                                </TableCell>
                                                <TableCell className='tablecellPrint'>
                                                    {row.user}
                                                </TableCell>
                                                <TableCell className='tablecellPrint' style={{ textAlign: 'right' }}>
                                                    {parseFloat(row.nettotal)?.toFixed(2)}
                                                </TableCell>
                                                <TableCell className='tablecellPrint' style={{ textAlign: 'right' }}>
                                                    {parseFloat(row.discount)?.toFixed(2)}
                                                </TableCell>
                                                <TableCell className='tablecellPrint' style={{ textAlign: 'right' }}>
                                                    {parseFloat(row.cashpaid)?.toFixed(2)}
                                                </TableCell>
                                                <TableCell className='tablecellPrint' style={{ textAlign: 'right' }}>
                                                    {parseFloat(row.bankpaid)?.toFixed(2)}
                                                </TableCell>
                                                <TableCell className='tablecellPrint' style={{ textAlign: 'right' }}>
                                                    {parseFloat(row.totalpaid)?.toFixed(2)}
                                                </TableCell>

                                                <TableCell className='tablecellPrint noprint' style={{ textAlign: 'center' }}>
                                                    <Button onClick={() => getSalesbyid(row.sales_id)} style={{ fontSize: 12 }} variant="primary">Print Bill</Button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                <TableRow>
                                    <TableCell colSpan={6} className='tablecellPrint' style={{ fontWeight: 'bold' }}>
                                        Total
                                    </TableCell>

                                    <TableCell className='tablecellPrint' style={{ textAlign: 'right', fontWeight: 'bold' }}>
                                        {netTot}
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{ textAlign: 'right', fontWeight: 'bold' }}>
                                        {discTot}
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{ textAlign: 'right', fontWeight: 'bold' }}>
                                        {cashTot}
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{ textAlign: 'right', fontWeight: 'bold' }}>
                                        {cardTot}
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{ textAlign: 'right', fontWeight: 'bold' }}>
                                        {totTot}
                                    </TableCell>

                                    <TableCell colSpan={1} className='tablecellPrint noprint' style={{ textAlign: 'right', fontWeight: 'bold' }}>

                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </TableContainer>
                {/* <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
            </Paper>
            <Modal
                size="lg"
                show={modal3}
                onHide={() => setModal3(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>
                    <Row >
                        <Col xs={12} style={{ marginTop: 20 }}>
                            <div className="section-to-print" ref={componentRef}>
                                <div className="invoice-box" style={{ border: 'none' }}>
                                    <div className="invoice-inside">
                                        <p style={{ textAlign: 'center', fontSize: 18, fontWeight: 'bold', marginBottom: 'unset' }}>Dekanet Beirut</p>
                                        <p style={{ textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset' }}>{branchName} - Doha</p>
                                        <p style={{ textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset' }}>Tel :30985888</p>

                                        <p style={{ textAlign: 'center', fontSize: 22, fontWeight: 'bold', marginBottom: 'unset' }}>{cardp != 0 && cashpb != 0 ? <span>Cash & Card Bill</span> : (cashpb == 0 ? <span>Card Bill</span> : <span>Cash Bill</span>)}</p>
                                        <p style={{ textAlign: 'center', fontSize: 14, fontWeight: 'bold' }}>{formatDate(new Date)}</p>
                                        <Row>
                                            <Col xs={12}>
                                                <p style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 'unset' }}>المورد</p>
                                                <p style={{ fontSize: 14, fontWeight: 500 }}>Bill No: {vouchernop}</p>
                                            </Col>
                                            {/* <Col xs={6}>
                                    <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>تاريخ</p>
                                    <p style={{fontSize: 14, fontWeight: 500}}>Date & Time: {formatDate(new Date)}</p>
                                    <p style={{fontSize: 14, fontWeight: 500, marginTop: 21}}>{vouchernop}</p>
                                </Col> */}
                                            {/* <Col xs={6}>
                                    <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>أمين الصندوق</p>
                                    <p style={{fontSize: 14, fontWeight: 500}}>Cashier: {voucherno}</p>
                                </Col> */}
                                            {/* <Col xs={6}>
                                    <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>تاريخ</p>
                                    <p style={{fontSize: 14, fontWeight: 500}}>Time: {date}</p>
                                </Col> */}
                                            <Col xs={6}>
                                                <p style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 'unset' }}>طريقة / مدة الدفع</p>
                                                <p style={{ fontSize: 14, fontWeight: 500 }}>Customer: Cash Customer</p>
                                            </Col>
                                            <Col xs={6}>
                                                <p style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 'unset' }}>المستعمل</p>
                                                <p style={{ fontSize: 14, fontWeight: 500 }}>User: {username}</p>
                                            </Col>
                                        </Row>
                                        {/* <div className='invoice-line' style={{width: '100%', height: 2, backgroundColor: '#d2d2d2'}}></div> */}
                                        <div>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th><p style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 'unset' }}>عدد</p>
                                                            <p style={{ fontSize: 14, fontWeight: 'bold' }}>SLNo.</p>
                                                        </th>
                                                        {/* <th><p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>منتج</p>
                                            <p style={{fontSize: 14, fontWeight: 'bold'}}>Product</p></th> */}
                                                        <th><p style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 'unset' }}>كمية</p>
                                                            <p style={{ fontSize: 14, fontWeight: 'bold' }}>Qty</p></th>
                                                        <th><p style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 'unset' }}>السعر</p>
                                                            <p style={{ fontSize: 14, fontWeight: 'bold' }}>Price</p></th>
                                                        <th><p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>الضريبة %</p>
                                                            <p style={{ fontSize: 12, fontWeight: 'bold' }}>Tax %</p></th>
                                                        <th><p style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 'unset' }}>مجموع</p>
                                                            <p style={{ fontSize: 14, fontWeight: 'bold' }}>Total</p></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        detailsp.map((item, index) => {
                                                            return (
                                                                <>
                                                                    <tr>
                                                                        <td style={{ borderBottom: 'none' }}>{index + 1}</td>
                                                                        {/* <td>{item.productname}</td> */}
                                                                        <td style={{ borderBottom: 'none' }}>{item.pb_isconvertunit == 1 ? (parseFloat(item.sd_qty / 1000) / parseFloat(item.sd_baseqty)) : (parseFloat(item.sd_qty) / parseFloat(item.sd_baseqty))} <span style={{ fontSize: 13 }}>{item.pb_displayconvertunit}</span></td>
                                                                        {/* <td style={{ borderBottom: 'none' }}>{item.pb_isconvertunit == 1 ? (parseFloat(item.price) / (parseFloat(item.sd_qty / 1000) / parseFloat(item.sd_baseqty))).toFixed(2) : (parseFloat(item.price) / (parseFloat(item.sd_qty) / parseFloat(item.sd_baseqty))).toFixed(2)}</td> */}
                                                                               
                                                                    <td>{ (item.pb_isconvertunit == 1 ? (parseFloat(item.calculated_price)/(parseFloat(item.sd_qty/1000)/parseFloat(item.sd_baseqty))) : (parseFloat(item.calculated_price)/(parseFloat(item.sd_qty)/parseFloat(item.sd_baseqty)))).toFixed(2)}</td>
                                                                    
                                                                    <td style={{ borderBottom: 'none' }}>{item.taxrate}%</td>
                                                                    <td style={{ borderBottom: 'none' }}>{item.calculated_totalprice}</td>

                                                                        {/* <td style={{ borderBottom: 'none' }}>{parseFloat(item.sd_total_amount).toFixed(2)}</td> */}
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={4} style={{ fontSize: 13 }}>
                                                                            {item.productname}
                                                                        </td>
                                                                    </tr>
                                                                </>

                                                            )

                                                        })
                                                    }

                                                    <tr>

                                                        <td colSpan={3}>
                                                            <p style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 'unset' }}>Sub Total / المجموع الفرعي</p>
                                                            {discountp == 0 ? null :
                                                                <p style={{ fontSize: 14, marginTop: 10, marginBottom: 'unset' }}>Discount / تخفيض</p>}
                                                            <p style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 'unset', marginTop: 10 }}>Grand Total / المبلغ الإجمالي</p>
                                                        </td>

                                                        <td>
                                                            <p style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 'unset' }}>{parseFloat(payable).toFixed(2)}</p>
                                                            {discountp == 0 ? null :
                                                                <p style={{ fontSize: 14, marginTop: 10, marginBottom: 'unset' }}>{discountp}</p>}
                                                            <p style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 'unset', marginTop: 10 }}>{parseFloat(payable).toFixed(2)}</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={3}>
                                                            {cashp == 0 ? null :
                                                                <p style={{ fontSize: 14, marginBottom: 'unset', marginTop: 10 }}>Cash / نقدي</p>}
                                                            {cardp == 0 ? null :
                                                                <p style={{ fontSize: 14, marginBottom: 'unset', marginTop: 10 }}>Card / بطاقة</p>}
                                                        </td>
                                                        <td>
                                                            {cashp == 0 ? null :
                                                                <p style={{ fontSize: 14, marginTop: 10, marginBottom: 'unset' }}>{cashp}</p>}
                                                            {cardp == 0 ? null :
                                                                <p style={{ fontSize: 14, marginTop: 10, marginBottom: 'unset' }}>{cardp}</p>}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={3}>

                                                            <p style={{ fontSize: 14, marginBottom: 'unset', marginTop: 10 }}>Paid / مدفوع</p>

                                                            <p style={{ fontSize: 14, marginBottom: 'unset', marginTop: 10 }}>Balance / الرصيد</p>
                                                        </td>
                                                        <td>

                                                            <p style={{ fontSize: 14, marginTop: 10, marginBottom: 'unset' }}>{paidp.toFixed(2)}</p>

                                                            <p style={{ fontSize: 14, marginTop: 10, marginBottom: 'unset' }}>{balancep.toFixed(2)}</p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                        <div style={{ marginTop: 30 }}>
                                            <div style={{ textAlign: 'center', height: '100' }} className='barr'>
                                                <Barcode value={vouchernop} />
                                            </div>
                                            <p style={{ textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset' }}>Thank You</p>
                                            <p style={{ textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset' }}>Visit Again!</p>

                                            {/* <p style={{textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset'}}>procurement@alawadi.qa</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </div>
    )
}
export default PosSummery;
