
import React, { useState, useEffect } from 'react';
import { Row, Col, Form } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { getFormGroupUtilityClass } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Map from '../../google-map/map';
import { MultiSelect } from "react-multi-select-component";
import Input from '@mui/material/Input';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import BtnLoader from '../../loader/btnLoader';
import Loader from '../../loader/loader';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";




function WizardFormFirstPage(props) {
  console.log("log", props.repList)

  return (
    <div>
      <Row>
        <Col sm={4} >

          {/* <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Code type</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.code}
                                label="Age"
                                onChange={props.codetypeChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                                  <MenuItem value="weighting">Weighting</MenuItem>
                                  <MenuItem value="normal">Normal</MenuItem>
                            </Select>
                    </FormControl> */}
          <FormControl sx={{ mt: 4, width: '100%' }}>
            <TextField name="productcode"
              disabled
              onChange={props.updateform}
              value={props.form.productcode} autoComplete="off" id="outlined-basic" label="Product Code" variant="outlined" size="small" />
          </FormControl>


          <FormControl sx={{ mt: 4, width: '100%' }}>
            <TextField name="refcode"
              onChange={props.updateform}
              value={props.form.refcode} autoComplete="off" id="outlined-basic" label="Reference code" variant="outlined" size="small" />
          </FormControl>


          <FormControl sx={{ mt: 4, width: '100%' }}>
            <TextField name="productname"
              onChange={props.updateform}
              value={props.form.productname} autoComplete="off" id="outlined-basic" label="Product name" variant="outlined" size="small" />
          </FormControl>

          <FormControl sx={{ mt: 4, width: '100%' }}>
            <TextField name="printname"
              onChange={props.updateform}
              value={props.form.printname} autoComplete="off" id="outlined-basic" label="Print name" variant="outlined" size="small" />
          </FormControl>

          <FormControl sx={{ mt: 4, width: '100%' }}>
            <TextField name="othername"
              onChange={props.updateform}
              value={props.form.othername} autoComplete="off" id="outlined-basic" label="Local Name" variant="outlined" size="small" />
          </FormControl>

          {/* <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">HSN</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.hsn}
                                label="Age"
                                onChange={props.hsnChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.hsnList.map((item, index) => (
                                  <MenuItem value={item.hsn_id}>{item.hsn_name}</MenuItem>
                                ))}
                            </Select>
                    </FormControl> */}

          <FormControl sx={{ mt: 4, width: '100%' }}>
            <InputLabel id="demo-simple-select-helper-label">Segment</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={props.segment}
              label="Age"
              onChange={props.segmentChange}
              size="small"

            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {props.segmentlist.map((item, index) => (
                <MenuItem value={item.segment_id}>{item.segment_name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ mt: 4, width: '100%' }}>
            <InputLabel id="demo-simple-select-helper-label">Sub Segment</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={props.subsegment}
              label="Age"
              onChange={props.subsegmentChange}
              size="small"

            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {props.subsegmentlist.map((item, index) => (
                <MenuItem value={item.subseg_id}>{item.subseg_name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {props.editmode == false ?
            <FormControl sx={{ mt: 4 }} component="fieldset">
              <RadioGroup
                defaultValue="credit"
                name="radio-buttons-group"
                onClick={props.radioChange}
              >
                {/* <FormControlLabel value="nonbatch"  control={<Radio checked={props.producttype == 0?  true: false}/>} label="Non Batch product" color = '#999'  /> */}
                <FormControlLabel value="batch" color='#999' control={<Radio checked={props.producttype == 1 ? true : false} />} label="Batch Product" />
              </RadioGroup>
            </FormControl> : null
          }
        </Col>
        <Col sm={4}>

          <FormControl sx={{ mt: 4, width: '100%' }}>
            <TextField name="description"
              onChange={props.updateform}
              value={props.form.description} autoComplete="off" id="outlined-basic" label="Description" variant="outlined" size="small" />
          </FormControl>

          {/* <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField name="warranty"
                            onChange={props.updateform}
                            value={props.form.warranty} autoComplete="off" id="outlined-basic" label="Warranty" variant="outlined"  size="small"/>
                        </FormControl> */}

          <FormControl sx={{ mt: 4, width: '100%' }}>
            <InputLabel id="demo-simple-select-helper-label">Department</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={props.brand}
              label="Age"
              onChange={props.brandChange}
              size="small"

            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {props.brandList.map((item, index) => (
                <MenuItem value={item.brand_id}>{item.brand_name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ mt: 4, width: '100%' }}>
            <InputLabel id="demo-simple-select-helper-label">Section</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={props.itemGroup}
              label="Age"
              onChange={props.itemgroupChange}
              size="small"

            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {props.itemgroupList.map((item, index) => (
                <MenuItem value={item.itemgroup_id}>{item.itemgroup_name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ mt: 4, width: '100%' }}>
            <InputLabel id="demo-simple-select-helper-label">Category</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={props.category}
              label="Age"
              onChange={props.categoryChange}
              size="small"

            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {props.categoryList.map((item, index) => (
                <MenuItem value={item.category_id}>{item.category_name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ mt: 4, width: '100%' }}>
            <InputLabel id="demo-simple-select-helper-label">Sub Categeory</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={props.subCategory}
              label="Age"
              onChange={props.subcategoryChange}
              size="small"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {props.subcatlist.map((item, index) => (
                <MenuItem value={item.subCategory_id}>{item.subCategory_name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ mt: 4, width: '100%' }}>
            <InputLabel id="demo-simple-select-helper-label">Value Added Tax</InputLabel>
            <Select
              name="vat"              
              onChange={props.updateform}
              value={props.form.vat}
              id="outlined-basic"
              size="small"   >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="1">Include Tax</MenuItem>
              <MenuItem value="2">Exclude Tax</MenuItem>
            </Select>
          </FormControl>


          {/* <FormControl sx={{ mt: 4, width: '100%' }}>
            <InputLabel id="demo-simple-select-helper-label">Value Added Tax</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={props.vat}
              label="Age"
              onChange={props.vatChange}
              size="small"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {props.vatList.map((item, index) => (
                <MenuItem value={item.taxmaster_id}>{item.taxmaster_name}</MenuItem>
              ))}
            </Select>
          </FormControl> */}


        </Col>
        <Col sm={4}>

          {/* <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Base unit</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.baseUnit}
                                label="Age"
                                onChange={props.baseunitChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.unitList.map((item, index) => (
                                  <MenuItem value={item.unit_id}>{item.unit_code}</MenuItem>
                                ))}
                            </Select>
                    </FormControl>

                    <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Purchase unit</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.purchaseUnit}
                                label="Age"
                                onChange={props.purchaseunitChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.unitList.map((item, index) => (
                                  <MenuItem value={item.unit_id}>{item.unit_code}</MenuItem>
                                ))}
                            </Select>
                    </FormControl>

                    <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Sale unit</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.salesUnit}
                                label="Age"
                                onChange={props.salesunitChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.unitList.map((item, index) => (
                                  <MenuItem value={item.unit_id}>{item.unit_code}</MenuItem>
                                ))}
                            </Select>
                    </FormControl>*/}

                    <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Price level</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.defaultPrice}
                                label="Age"
                                onChange={props.defaultpriceChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.priceList.map((item, index) => (
                                  <MenuItem value={item.pricelevel_id}>{item.pricelevel_name}</MenuItem>
                                ))}
                            </Select>
                    </FormControl> 

          <FormControl sx={{ mt: 4, width: '100%' }}>
            <TextField name="orderlevel"
              onChange={props.updateform}
              value={props.form.orderlevel} autoComplete="off" id="outlined-basic" label="Order Level" variant="outlined" size="small" />
          </FormControl>

          <FormControl sx={{ mt: 4, width: '100%' }}>
            <TextField name="orderqty"
              onChange={props.updateform}
              value={props.form.orderqty} autoComplete="off" id="outlined-basic" label="Order Quantitiy" variant="outlined" size="small" />
          </FormControl>

          <FormControl sx={{ mt: 4, width: '100%' }}>
            <InputLabel id="demo-simple-select-helper-label">Zone (Display location)</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={props.dlocation}
              label="Age"
              onChange={props.dlocationChange}
              size="small"

            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {props.dlocationlist.map((item, index) => (
                <MenuItem value={item.disloc_id}>{item.disloc_name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ mt: 4, width: '100%' }}>
            <InputLabel id="demo-simple-select-helper-label">Country of Origin</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={props.country}
              label="Age"
              onChange={props.countryChange}
              size="small"

            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {props.countrylist.map((item, index) => (
                <MenuItem value={item.country_id}>{item.country_name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ mt: 4, width: '100%' }}>
            <InputLabel id="demo-simple-select-helper-label">Godown</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={props.godown}
              label="Age"
              onChange={props.godownChange}
              size="small"

            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {props.godownList.map((item, index) => (
                <MenuItem value={item.godown_id}>{item.godown_name}</MenuItem>
              ))}
            </Select>
          </FormControl>
      
          <FormControl sx={{ mt: 4, width: '100%' }}>
            <TextField name="tax"
              onChange={props.updateform}
              value={props.form.tax} autoComplete="off" id="outlined-basic" label="Tax Rate" variant="outlined" size="small" />
          </FormControl>

          <FormControl sx={{ width: '100%' }} style={{ marginTop: 20 }}>
            <FormControlLabel control={
              <Checkbox checked={props.blocked == 0 ? false : true} onChange={e => props.checkchange(e)} />
            } label="Blocked" />
          </FormControl>
        </Col>
      </Row>
      {
        props.editmode ?
          <Row style={{ marginTop: 40 }}>
            <Col sm={3}>
              <TextField name="dummysale"
                onChange={props.updateform}
                value={props.form.dummysale} autoComplete="off" id="outlined-basic" label="Bulk Sale Price" variant="outlined" size="small" />
            </Col>
            <Col sm={3}>
              <TextField name="dummypurchase"
                onChange={props.updateform}
                value={props.form.dummypurchase} autoComplete="off" id="outlined-basic" label="Bulk Purchase Price" variant="outlined" size="small" />
            </Col>
          </Row> :
          null
      }

    </div>
  );
}

export default WizardFormFirstPage;
